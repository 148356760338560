<template>
  <b-card class="shop-agents">
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group :label="$t('From Date')">
          <b-form-datepicker
            v-model="dataInfo.fromDate"
            locale="en"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group :label="$t('To Date')">
          <b-form-datepicker
            v-model="dataInfo.toDate"
            locale="en"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
        class="mt-auto mb-1"
      >
        <b-button
          variant="primary"
          @click="loadAgentDashboard"
        >
          {{ $t('Search') }}
        </b-button>
      </b-col>
      <b-col cols="12">
        <good-data-table
          ref="agentsGrid"
          :columns="agentsColumns"
          :total-column="'response.responseData.total'"
          :data-column="`Object.values(response.responseData.data).filter(x => typeof x === 'object')`"
          :api-endpoint="'/shop/agents/data/statistics'"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="showAgentSelectionModal"
      :no-close-on-backdrop="true"
      :title="$t('Choose Orders')"
      size="xl"
      :ok-title="$t('Collect Amount')"
      :cancel-title="$t('Dismiss')"
      @hidden="showAgentSelectionModal = false"
      @ok="depositCOD"
    >
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-hover">
          <thead>
            <th>
              {{ $t('Action') }}
              <b-form-checkbox
                v-model="selectAllOrders"
                switch
                inline
                @change="selectAll($event)"
              />
            </th>
            <th>{{ $t('Invoice No.') }}</th>
            <th>{{ $t('Total Amount') }}</th>
            <th>{{ $t('Customer') }}</th>
            <th>{{ $t('Delivery') }}</th>
          </thead>
          <tbody>
            <tr v-if="agentOrdersInfo.length === 0">
              <td
                colspan="5"
                class="text-center"
              >
                {{ $t('No Data Found') }}......
              </td>
            </tr>
            <tr
              v-for="(item, index) of agentOrdersInfo"
              :key="index"
            >
              <td>
                <b-form-checkbox
                  v-model="item.agentSelected"
                  switch
                  inline
                />
              </td>
              <td>{{ item.store_order_invoice_id }}</td>
              <td>{{ item.currency }} {{ item.total_amount }}</td>
              <td>{{ item.user ? item.user.first_name + ' ' + item.user.last_name : '' }}</td>
              <td>{{ item.delivery ? item.delivery.map_address + ' ' + item.delivery.street : '' }}</td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="text-left"
              >
                {{ $t('No. of orders selected:') }} {{ ordersSelected }}
              </td>
              <td
                colspan="3"
                class="text-left"
              >
                {{ $t('Total Amount:') }} {{ orderSelectedAmount }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BFormCheckbox, BFormGroup, BFormDatepicker, BButton, BModal } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import moment from 'moment'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from './useStore'

export default {
  components: {
    BFormCheckbox,
    GoodDataTable,
    BCard,
    BCol,
    BRow,
    BModal,
    BFormGroup,
    BFormDatepicker,
    BButton,
  },
  data() {
    return {
      dataInfo: {
        fromDate: '',
        toDate: '',
      },
      showAgentSelectionModal: false,
      selectAllOrders: false,
      agentOrdersInfo: [],
      selectedAgentOrders: [],
      agentInfo: null,
      agentsColumns: [
        {
          label: 'Driver Name',
          field: 'first_name',
          useHtml: true,
          excelHtml: props => `${props.first_name} ${props.last_name}`,
          renderer: props => `<div class="d-flex align-items-center">
                                <img class="agent-image-info" src="${window.SuperApp.helper.image(props.picture)}" onerror="this.src = '${window.SuperApp.constants.noImageAvailable}'" />
                                <span>${props.first_name} ${props.last_name}</span>
                              </div>`,
        },
        {
          label: 'Dispatched',
          field: 'dispatched_order_count',
        },
        {
          label: 'Attempted',
          field: 'attempted_order_count',
        },
        {
          label: 'Delivered',
          field: 'completed_order_count',
        },
        {
          label: 'Delivery Rate',
          field: 'delivery_rate',
          useRenderer: true,
          renderer: props => (props.delivery_rate ? `${props.delivery_rate}%` : '0%'),
        },
        {
          label: 'Collected COD',
          field: 'collected_cod',
        },
        {
          label: 'Deposited COD',
          field: 'deposited_cod',
        },
        {
          label: 'Pending COD',
          field: 'pending_cod',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('payment'),
              text: 'Collect COD',
              action: props => {
                this.collectCOD(props)
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    ordersSelected() {
      return this.agentOrdersInfo.filter(x => x.agentSelected).length
    },
    orderSelectedAmount() {
      return this.agentOrdersInfo
        .filter(x => x.agentSelected && x.total_amount)
        .map(x => parseFloat(x.total_amount))
        .reduce((a, b) => a + b, 0).toFixed(2)
    },
  },
  methods: {
    loadAgentDashboard() {
      const dataPayload = []
      if (this.dataInfo.fromDate) {
        dataPayload.push(`from=${moment(this.dataInfo.fromDate).format('YYYY-MM-DD')}`)
      }
      if (this.dataInfo.toDate) {
        dataPayload.push(`to=${moment(this.dataInfo.toDate).format('YYYY-MM-DD')}`)
      }
      this.$refs.agentsGrid.loadItems(dataPayload.join('&'))
    },
    selectAll($event) {
      this.agentOrdersInfo.forEach(x => {
        // eslint-disable-next-line no-param-reassign
        x.agentSelected = $event
      })
      this.$forceUpdate()
    },
    collectCOD(payload) {
      this.agentInfo = payload
      const { agentsPendingCODOrders } = useStoreUi()

      showLoader()
      agentsPendingCODOrders(`?statuses=COMPLETED&finance_status=COLLECTED&agent_id=${payload.id}&length=1000`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            const agentOrdersInfo = data.responseData.data
            agentOrdersInfo.forEach(x => {
              // eslint-disable-next-line no-param-reassign
              x.agentSelected = false
            })
            this.agentOrdersInfo = agentOrdersInfo
            this.selectAllOrders = false
            this.showAgentSelectionModal = true
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    depositCOD() {
      const { depositAgentCOD } = useStoreUi()
      if (this.agentOrdersInfo.filter(x => x.agentSelected).length === 0) {
        return
      }
      showLoader()
      depositAgentCOD({
        order_ids: this.agentOrdersInfo
          .filter(x => x.agentSelected)
          .map(x => x.id)
          .join(','),
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.agentsGrid.loadItems()
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style>
.agent-image-info {
  width: 40px;
  height: 40px;
  border-radius: 10%;
  margin-right: 10px;
}
</style>
