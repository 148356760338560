import store from '@/store'

export const useStore = () => {}

export const useStoreUi = () => {
  const storeProfile = payload => store.dispatch('app-store/getStoreProfile', payload)

  const updateStoreProfile = payload => store.dispatch('app-store/updateStoreProfile', payload)

  const getNotifications = () => store.dispatch('app-store/getNotifications')

  const getStoreOrders = payload => store.dispatch('app-store/getStoreOrders', payload)

  const getStoreTypeList = () => store.dispatch('app-store/getStoreTypeList')

  const createCategory = payload => store.dispatch('app-store/createCategory', payload)

  const updateCategory = payload => store.dispatch('app-store/updateCategory', payload)

  const updateCategoryStatus = payload => store.dispatch('app-store/updateCategoryStatus', payload)

  const getStoreCountryCities = payload => store.dispatch('app-store/getStoreCountryCities', payload)

  const addShopAgent = payload => store.dispatch('app-store/addShopAgent', payload)

  const updateShopAgent = payload => store.dispatch('app-store/updateShopAgent', payload)

  const updateAgentStatus = payload => store.dispatch('app-store/updateAgentStatus', payload)

  const updateItemsStatus = payload => store.dispatch('app-store/updateItemsStatus', payload)

  const addShopItems = payload => store.dispatch('app-store/addShopItems', payload)

  const shopCategoryList = payload => store.dispatch('app-store/shopCategoryList', payload)

  const getShopItems = payload => store.dispatch('app-store/getShopItems', payload)

  const shopAddOnsList = payload => store.dispatch('app-store/shopAddOnsList', payload)

  const updateShopItems = payload => store.dispatch('app-store/updateShopItems', payload)

  const getShopCategory = payload => store.dispatch('app-store/getShopCategory', payload)

  const getShopAddOns = payload => store.dispatch('app-store/getShopAddOns', payload)

  const getStoreAgentById = payload => store.dispatch('app-store/getShopAgentById', payload)

  const shopRequestHistory = payload => store.dispatch('app-store/shopRequestHistory', payload)

  const paymentConfig = () => store.dispatch('app-store/paymentConfig')

  const bankTemplates = () => store.dispatch('app-store/bankTemplates')

  const dispatcherInfo = payload => store.dispatch('app-store/dispatcherInfo', payload)

  const getShopUnits = () => store.dispatch('app-store/getShopUnits')

  const updatePromocode = payload => store.dispatch('app-store/updatePromocode', payload)

  const createPromocode = payload => store.dispatch('app-store/createPromocode', payload)

  const getPromocodeInfo = payload => store.dispatch('app-store/getPromocodeInfo', payload)

  const deletePromocode = payload => store.dispatch('app-store/deletePromocode', payload)

  const updateBankDetails = payload => store.dispatch('app-store/updateBankDetails', payload)

  const updatePaymentConfig = payload => store.dispatch('app-store/updatePaymentConfig', payload)

  const getAgentsStats = () => store.dispatch('app-store/getAgentsStats')

  const searchByPhoneNumber = payload => store.dispatch('app-store/searchByPhoneNumber', payload)

  const searchByEmail = payload => store.dispatch('app-store/searchByEmail', payload)

  const createUser = payload => store.dispatch('app-store/createUser', payload)

  const createUserAddress = payload => store.dispatch('app-store/createUserAddress', payload)

  const shopPricingInfo = payload => store.dispatch('app-store/shopPricingInfo', payload)

  const agentOrderDetail = payload => store.dispatch('app-store/agentOrderDetail', payload)

  const createManualOrder = payload => store.dispatch('app-store/createManualOrder', payload)

  const shopAcceptedOrders = payload => store.dispatch('app-store/shopAcceptedOrders', payload)

  const storeReview = () => store.dispatch('app-store/storeReview')

  const getShopPrice = () => store.dispatch('app-store/getShopPrice')

  const storeCancelOrder = payload => store.dispatch('app-store/storeCancelOrder', payload)

  const storeAcceptOrder = payload => store.dispatch('app-store/storeAcceptOrder', payload)

  const storeCartList = payload => store.dispatch('app-store/storeCartList', payload)

  const storeAddCart = payload => store.dispatch('app-store/storeAddCart', payload)

  const storeRemoveCart = payload => store.dispatch('app-store/storeRemoveCart', payload)

  const shopAssignAgentToOrder = payload => store.dispatch('app-store/shopAssignAgentToOrder', payload)

  const saveDeliverySettings = payload => store.dispatch('app-store/saveDeliverySettings', payload)

  const findAgentForOrder = payload => store.dispatch('app-store/findAgentForOrder', payload)

  const deleteStoreReview = payload => store.dispatch('app-store/deleteStoreReview', payload)

  const createOverview = payload => store.dispatch('app-store/createOverview', payload)

  const editOverview = payload => store.dispatch('app-store/editOverview', payload)

  const getOvewViewById = payload => store.dispatch('app-store/getOvewViewById', payload)

  const storePhotos = payload => store.dispatch('app-store/storePhotos', payload)

  const getPhotoDetail = payload => store.dispatch('app-store/getPhotoDetail', payload)

  const deleteStorePhoto = payload => store.dispatch('app-store/deleteStorePhoto', payload)

  const editStorePhoto = payload => store.dispatch('app-store/editStorePhoto', payload)

  const addStorePhoto = payload => store.dispatch('app-store/addStorePhoto', payload)

  const getOrderDetailsById = payload => store.dispatch('app-store/getOrderDetailsById', payload)

  const shopAgentList = () => store.dispatch('app-store/shopAgentList')

  const depositAgentCOD = payload => store.dispatch('app-store/depositAgentCOD', payload)

  const storeRequestHistoryList = payload => store.dispatch('app-store/storeRequestHistoryList', payload)

  const getLocationInfo = payload => store.dispatch('app-store/getLocationInfo', payload)

  const shopTransaction = payload => store.dispatch('app-store/shopTransaction', payload)

  const shopNotificationCount = () => store.dispatch('app-store/shopNotificationCount')

  const shopNotifications = payload => store.dispatch('app-store/shopNotifications', payload)

  const getChatNotifications = () => store.dispatch('app-store/getChatNotifications')

  const revertReview = payload => store.dispatch('app-store/revertReview', payload)

  const bookedTimeSlots = payload => store.dispatch('app-store/bookedTimeSlots', payload)

  const agentsPendingCODOrders = payload => store.dispatch('app-store/agentsPendingCODOrders', payload)

  const createAddOns = payload => store.dispatch('app-store/createAddOns', payload)

  const updateAddOns = payload => store.dispatch('app-store/updateAddOns', payload)

  const shopOrders = () => store.dispatch('app-store/shopOrders')

  const shopReview = payload => store.dispatch('app-store/shopReview', payload)

  const executeGetApi = payload => store.dispatch('app-store/executeGetApi', payload)

  const getDefaultStoreUser = () => store.dispatch('app-store/getDefaultStoreUser')

  const shopListDocument = payload => store.dispatch('app-store/shopListDocument', payload)

  const deleteShopDocument = payload => store.dispatch('app-store/deleteShopDocument', payload)

  const uploadShopDocument = payload => store.dispatch('app-store/uploadShopDocument', payload)

  const shopAdminServices = () => store.dispatch('app-store/shopAdminServices')

  const changeShopStatus = payload => store.dispatch('app-store/changeShopStatus', payload)

  const reScheduleRequest = payload => store.dispatch('app-store/reScheduleRequest', payload)

  const shopUploadItems = payload => store.dispatch('app-store/shopUploadItems', payload)

  const storeAutoComplete = payload => store.dispatch('app-store/storeAutoComplete', payload)

  const shopTimeslotDetails = () => store.dispatch('app-store/shopTimeslotDetails')

  const shopItems = () => store.dispatch('app-store/shopItems')

  const getVariationById = payload => store.dispatch('app-store/getVariationById', payload)

  const createVaraition = payload => store.dispatch('app-store/createVaraition', payload)

  const updateVariation = payload => store.dispatch('app-store/updateVariation', payload)

  const deleteVariation = payload => store.dispatch('app-store/deleteVariation', payload)

  const getVariationsList = () => store.dispatch('app-store/getVariationsList')

  const courierCompany = payload => store.dispatch('app-store/courierCompany', payload)

  const saveCourierCompany = payload => store.dispatch('app-store/saveCourierCompany', payload)

  const saveShopStaticPage = payload => store.dispatch('app-store/saveShopStaticPage', payload)

  const getShopStaticPage = payload => store.dispatch('app-store/getShopStaticPage', payload)

  const generateQr = payload => store.dispatch('app-store/generateQr', payload)

  const printShopPdf = payload => store.dispatch('app-store/printShopPdf', payload)

  const shopChangePassword = payload => store.dispatch('app-store/shopChangePassword', payload)

  const shopPromocodes = payload => store.dispatch('app-store/shopPromocodes', payload)

  const shipRocketPickup = payload => store.dispatch('app-store/shipRocketPickup', payload)

  const shopRocketManifest = payload => store.dispatch('app-store/shopRocketManifest', payload)

  const shopRocketLabel = payload => store.dispatch('app-store/shopRocketLabel', payload)

  const shopRocketInvoice = payload => store.dispatch('app-store/shopRocketInvoice', payload)

  const shopRocketTracking = payload => store.dispatch('app-store/shopRocketTracking', payload)

  const getStoreTiming = payload => store.dispatch('app-store/getStoreTiming', payload)

  const saveStoreTiming = payload => store.dispatch('app-store/saveStoreTiming', payload)

  return {
    getStoreTiming,
    saveStoreTiming,
    shopRocketTracking,
    shopRocketInvoice,
    shopRocketLabel,
    shopRocketManifest,
    shipRocketPickup,
    shopPromocodes,
    shopChangePassword,
    printShopPdf,
    generateQr,
    getShopStaticPage,
    saveShopStaticPage,
    saveCourierCompany,
    courierCompany,
    getVariationsList,
    getVariationById,
    createVaraition,
    updateVariation,
    deleteVariation,
    shopItems,
    shopTimeslotDetails,
    storeAutoComplete,
    shopUploadItems,
    getChatNotifications,
    reScheduleRequest,
    changeShopStatus,
    shopAdminServices,
    shopListDocument,
    deleteShopDocument,
    uploadShopDocument,
    getDefaultStoreUser,
    executeGetApi,
    shopReview,
    shopOrders,
    updateAddOns,
    createAddOns,
    getShopAddOns,
    agentsPendingCODOrders,
    bookedTimeSlots,
    revertReview,
    shopNotifications,
    shopNotificationCount,
    shopTransaction,
    getLocationInfo,
    storeRequestHistoryList,
    depositAgentCOD,
    shopAgentList,
    getOrderDetailsById,
    addStorePhoto,
    editStorePhoto,
    deleteStorePhoto,
    getPhotoDetail,
    storePhotos,
    createOverview,
    editOverview,
    getOvewViewById,
    deleteStoreReview,
    findAgentForOrder,
    saveDeliverySettings,
    storeRemoveCart,
    shopAssignAgentToOrder,
    storeAddCart,
    storeCartList,
    storeAcceptOrder,
    storeCancelOrder,
    getShopPrice,
    storeReview,
    shopAcceptedOrders,
    createManualOrder,
    agentOrderDetail,
    shopPricingInfo,
    createUserAddress,
    createUser,
    searchByPhoneNumber,
    searchByEmail,
    getAgentsStats,
    updatePaymentConfig,
    updateBankDetails,
    deletePromocode,
    getPromocodeInfo,
    createPromocode,
    updatePromocode,
    getShopCategory,
    getShopUnits,
    storeProfile,
    updateStoreProfile,
    getNotifications,
    getStoreOrders,
    getStoreTypeList,
    createCategory,
    updateCategory,
    updateCategoryStatus,
    getStoreCountryCities,
    addShopAgent,
    updateShopAgent,
    updateAgentStatus,
    updateItemsStatus,
    addShopItems,
    shopCategoryList,
    getShopItems,
    shopAddOnsList,
    updateShopItems,
    getStoreAgentById,
    shopRequestHistory,
    paymentConfig,
    bankTemplates,
    dispatcherInfo,
  }
}
